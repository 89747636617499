// Post slugs exported here will be displayed as medically reviewed by Dr. G
module.exports = {
	"gastric-bypass-dumping-syndrome": ["gintas"],
	"gastric-bypass-pre-op-diet": ["gintas"],
	"gastric-bypass-and-diabetes": ["gintas"],
	"gastric-sleeve-and-alcohol": ["gintas"],
	"pregnancy-after-gastric-sleeve-surgery": ["gintas"],
	"gastric-sleeve-and-diabetes": ["gintas"],
	"gastric-bypass-and-hair-loss": ["gintas"],
	"liver-shrinking-diet": ["gintas"],
	"gastric-bypass-reversal": ["gintas"],
	"eating-one-week-after-gastric-sleeve": ["gintas"],
	"bariatric-surgery-and-popcorn": ["gintas"],
	"nsaids-after-gastric-bypass-surgery": ["gintas"],
	"gastric-sleeve-surgery-and-pcos": ["gintas"],
	"gastric-sleeve-diet-cheating": ["gintas"],
	"pcos-and-gastric-bypass": ["gintas"],
	"vsg-diet-sleeve-gastrectomy": ["gintas"],
	"bariatric-surgery-and-alcohol": ["gintas"],
	"pcos-and-weight-loss-surgery": ["gintas"],
	"ketogenic-diet-after-gastric-sleeve-surgery": ["gintas"],
	"gastric-sleeve-revision": ["gintas"],
	"bariatric-hair-loss": ["gintas"],
	"gastric-bypass-blood-sugar": ["gintas"],
	"revision-weight-loss-surgery": ["gintas"],
	"bariatric-dumping": ["gintas"],
	"lap-band-revision": ["gintas"],
	"loose-skin-after-weight-loss-surgery": ["gintas"],
	"bariatric-surgery-and-diabetes": ["gintas"],
	"gastric-bypass-complications": ["gintas"],
	"duodenal-switch-complications": ["gintas"],
	"bariatric-plastic-surgery": ["gintas"],
	"astric-sleeve-vs-gastric-bypass": ["gintas"],
	"gastric-sleeve-hair-loss": ["gintas"],
	"constipation-after-gastric-sleeve": ["gintas"],
	"how-to-tighten-loose-skin-after-gastric-bypass-without-surgery": ["gintas"],
	"gastric-bypass-for-gastroparesis": ["gintas"],
	"gastric-sleeve-regrets": ["gintas"],
	"gastric-bypass-and-alcohol": ["gintas"],
	"gastric-sleeve-complications": ["gintas"],
	"cheating-on-liquid-diet-before-gastric-bypass": ["gintas"],
	"gastric-sleeve-pouch-reset": ["gintas"],
	"dumping-syndrome-gastric-sleeve": ["gintas"],
	"gastric-bypass-pouch-reset": ["gintas"],
	"bariatric-plate": ["gintas"],
	"bariatric-surgery-books": ["gintas"],
	"acid-reflux-after-gastric-sleeve": ["gintas"],
	"gastric-bypass-failure": ["gintas"],
	"gastric-bypass-app": ["gintas"],
	"bariatric-surgery-recovery-time": ["gintas"],
	"constipation-after-gastric-bypass": ["gintas"],
	"gastric-sleeve-horror-stories": ["gintas"],
	"what-is-lap-band-surgery": ["gintas"],
	"personality-changes-after-bariatric-surgery": ["gintas"],
	"bariatric-app": ["gintas"],
	"pain-after-bariatric-surgery": ["gintas"],
	"hiatal-hernia-after-gastric-bypass": ["gintas"],
	"gastric-sleeve-surgery-recovery-time": ["gintas"],
	"gastric-sleeve-diet-after-one-year": ["gintas"],
	"pain-after-gastric-sleeve": ["gintas"],
	"weight-loss-surgery-failure": ["gintas"],
	"bariatric-surgery-forum": ["gintas"],
	"bariatric-medical-alert-bracelet": ["gintas"],
	"gastric-sleeve-scars": ["gintas"],
	"gastric-sleeve-forum": ["gintas"],
	"gastric-bypass-recovery-time": ["gintas"],
	"gastric-sleeve-failure-rate": ["gintas"],
	"gastric-sleeve-app": ["gintas"],
	"cost-of-gastric-sleeve-surgery-without-insurance": ["gintas"],
	"lap-band-pain": ["gintas"],
	"weight-loss-surgery-horror-stories": ["gintas"],
	"gastric-bypass-scars": ["gintas"],
	"hiatal-hernia-and-gastric-sleeve": ["gintas"],
	"how-to-pay-for-bariatric-surgery-without-insurance": ["gintas"],
	"gastric-bypass-surgery-cost-without-insurance": ["gintas"],
	"stomach-pain-after-gastric-bypass": ["gintas"],
	"gastric-bypass-success-stories": ["gintas"],
	"gastric-bypass-forum": ["gintas"],
	"bariatric-patients-can-boost-their-immune-system-during-coronavirus": [
		"gintas",
	],
	"are-obese-people-more-susceptible-to-coronavirus": ["gintas"],
	"gastric-sleeve-expected-weight-loss": ["gintas"],
	"bariatric-desserts": ["gintas"],
	"hypnosis-gastric-bypass": ["gintas"],
	"gastric-bypass-alternative": ["gintas"],
	"bariatric-surgery-requirements": ["gintas"],
	"gastric-bypass-requirements": ["gintas"],
	"bariatric-surgery-alternatives": ["gintas"],
	"diarrhea-after-gastric-bypass": ["gintas"],
	"is-gastric-bypass-safe": ["gintas"],
	"how-to-shrink-liver-in-3-days": ["gintas"],
	"lap-band-and-pregnancy": ["gintas"],
	"acid-reflux-after-gastric-bypass": ["gintas"],
	"gastric-bypass-diet-menu": ["gintas"],
	"lap-band-pre-op-diet": ["gintas"],
	"heartburn-after-gastric-bypass-surgery": ["gintas"],
	"gastric-bypass-ulcers": ["gintas"],
	"keto-diet-after-bariatric-surgery": ["gintas"],
	"lap-band-acid-reflux": ["gintas"],
	"weight-loss-stall-after-gastric bypass": ["gintas"],
	"pros-and-cons-of-bariatric-surgery": ["gintas"],
	"heartburn-with-lap-band": ["gintas"],
	"gastric-bypass-problems": ["gintas"],
	"gastric-balloon-surgery-cost": ["gintas"],
	"lap-band-diet-menu": ["gintas"],
	"lap-band-liquid-diet": ["gintas"],
	"duodenal-switch-cost": ["gintas"],
	"weight-loss-surgery-insurance-secrets": ["gintas"],
	"gastric-bypass-vitamin-schedule": ["gintas"],
	"gastric-bypass-vitamin-deficiency": ["gintas"],
	"pros-and-cons-of-gastric-bypass": ["gintas"],
	"gastric-sleeve-risks": ["gintas"],
	"gastric=bypass-vitamins": ["gintas"],
	"bariatric-vitamin-schedule": ["gintas"],
	"gastric-bypass-cost": ["gintas"],
};
